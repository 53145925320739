.sc-story-container .sc-story-body {
  --sc-story-height: 80px;
  --sc-story-width: 80px;
  --sc-story-badge-color: #ffffff;
  --sc-story-badge-bg-color: #d61f79;
  --sc-story-border-color: #d61f79;
  --sc-story-seen-border-color: #949496;
  --sc-story-badge-font-style: 10px;
  --sc-story-badge-height: 20px;
  --sc-story-badge-width: 20px;
}
.sc-story-container .sc-story-body .sc-story:before {
  content: unset !important;
}

.sc-popup-container .sc-story-item .sc-poster-container {
  color: #ffffff !important;
}

.stories-container {
  display: grid;
  border-bottom: 1px solid #e2e5e9;
  align-items: flex-start;
  margin-bottom: 24px;
  padding-bottom: 14px;
}
.stories-container .stories-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.stories-container .stories-list {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
  overflow-x: auto;
  padding: 0;
  justify-content: flex-start;
}
.stories-container .stories-list .story-item {
  padding-bottom: 10px;
}
@media (max-width: 768px) {
  .stories-container {
    margin-left: -16px;
    margin-right: -16px;
    margin-bottom: 0;
  }
  .stories-container .stories-header {
    margin: 0 17px 20px;
  }
  .stories-container .stories-header h3 {
    font-size: calc(14px + var(--big-fs))  !important;
    line-height: 18px  !important;
  }
  .stories-container #stories {
    padding: 0 16px;
  }
}

.story-item-title a {
  font-weight: 600;
  margin: 2px auto 0;
  text-align: center;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  width: fit-content;
  max-width: 78px;
  white-space: nowrap;
  font-size: calc(12px + var(--big-fs))  !important;
  line-height: 16px  !important;
  color: #d61f79;
}
.story-item-title a:hover {
  color: #c01c6c;
}
.story-item-title a:active {
  color: #a91960;
}
.story-item-title span {
  display: block;
  text-align: center;
  color: #949496;
  font-size: calc(10px + var(--big-fs))  !important;
  line-height: 14px  !important;
}

.story-item-actions {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 4px;
}
.story-item-actions a {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2px;
  color: #ffffff;
}
.story-item-actions a i {
  font-size: calc(16px + var(--big-fs));
  line-height: 1;
}
.story-item-actions a span {
  text-transform: capitalize;
  font-size: calc(14px + var(--big-fs));
  line-height: 18px;
}

.stories.carousel {
  margin-top: 0 !important;
}

#stories {
  width: 100% !important;
  box-sizing: border-box;
  overflow: auto !important;
}
